import React, { useRef } from 'react'
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import { useTranslation } from 'react-i18next';
import { foodApi } from '../../../apis/Food/api';
import { objectFilter } from '../../../helpers/objectFilter';

const CheckSlug = ({ id, register, type, isUnique, setIsUnique }) => {

    const { t } = useTranslation();

    const delayRef = useRef(null);

    const checkSlug = async (slug) => {
        try {
            await foodApi.checkSlug(type, objectFilter({ slug, id }));
            setIsUnique(true);
        } catch (error) {
            if (error.response.data.message === "Slug exist") {
                setIsUnique(false);
            }
        }
    }

    const handleChange = (e) => {
        clearTimeout(delayRef.current);
        if (e.target.value) delayRef.current = setTimeout(() => checkSlug(e.target.value), 500);
    }

  return (
    <div>
        <CustomLabel>
            Slug
        </CustomLabel>
        <CustomInput
            style={{ borderColor: isUnique === false ? 'red' : isUnique === true ? 'green' : null }}
            {...register("slug")}
            onChange={handleChange}
        />
        {isUnique === false && <div className="text-danger mt-2">{t('Slug must be unique')}</div>}
    </div>
  )
}

export default CheckSlug