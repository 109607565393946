import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../../../Custom/CustomInput'
import CustomLabel from '../../../../../Custom/CustomLabel'

const PizzaSelection = ({data, setData}) => {

    const handleChange = (itemId, field, value) => {
        setData(prev => prev.map(item => {
            if (item.id === itemId) return {
                ...item,
                [field]: value
            }

            return item
        }))
    }

  return (
    <Row className='mt-2 gap-2'>
        {data.map(item => (
            <Col 
                key={item.id} 
                md={5} 
                className="border p-2"
                style={{
                    display: 'grid', 
                    gridTemplateColumns: '1fr 2fr 1fr',
                    gap: "2rem", 
                    alignItems: 'center',
                }} 
            >
                    <div className="form-check form-check-primary">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`pizza-${item.id}`}
                            checked={item.included || ''}
                            onChange={e => handleChange(item.id, 'included', e.target.checked)}
                        />
                        <CustomLabel className="form-check-label" htmlFor={`pizza-${item.id}`}>{item.name}</CustomLabel>
                    </div>

                    <div>
                        <CustomInput
                            placeholder="Extra"
                            value={item.price || ''}
                            onChange={e => handleChange(item.id, 'price', e.target.value)}
                            disabled={!item.included}
                        />
                    </div>
                    
                    <div className="form-check form-switch">
                        <input 
                            type="checkbox" 
                            role="switch" 
                            id={`pizza-item-${item.id}-default`} 
                            className="form-check-input fs-16"
                            checked={item.included && item.default} 
                            onChange={e => handleChange(item.id, 'default', e.target.checked)} 
                            disabled={!item.included} 
                        />
                        <CustomLabel className="form-check-label" htmlFor={`pizza-item-${item.id}-default`}>Default</CustomLabel>
                    </div>
                 
            </Col>
        ))}
    </Row>
  )
}

export default PizzaSelection