import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useComplaints } from "../../../hooks/useQueryHelpers";
import ComplaintsTable from "../../../Components/Settings/Complaints/Table";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { objectFilter } from "../../../helpers/objectFilter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CreateButton from "../../../Components/Common/Buttons/CreateButton";

const Complaints = () => {

  const debounceRef = useRef(null);

  const [queryValues, setQueryValues] = useState({
    type: '',
    status: '',
    branche_name: '',
    created_at: '',
  })

  const [debouncedQueryValues, setDebouncedQueryValues] = useState({
    user_name: '',
    user_phone: ''
  })

  const { isLoading, isRefetching, data, refetch } = useComplaints(objectFilter({...queryValues, ...debouncedQueryValues}));

  useEffect(() => {
    refetch();
  }, [queryValues])

  useEffect(() => {
    debounceRef.current = setTimeout(() => refetch(), 500);
    return () => clearTimeout(debounceRef.current);
  }, [debouncedQueryValues])

  const complaints = data?.data?.data ?? [];

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Settings" title="Complaints" to={getPathname('settings/complaints')} />
      <Row className="gy-4">
        <Col>
          <Link to={getPathname('settings/complaints/create')}>
            <CreateButton />
          </Link>
        </Col>
        <Col xs={12}>
          <Card>
            <ComplaintsTable
              data={complaints}
              isLoading={isLoading}
              queryValues={queryValues}
              setQueryValues={setQueryValues}
              debouncedQueryValues={debouncedQueryValues}
              setDebouncedQueryValues={setDebouncedQueryValues}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Complaints;
