import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, Col, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { useBranches, useComplaintTypes } from "../../../hooks/useQueryHelpers";
import CustomTextArea from "../../Custom/CustomTextArea";
import CustomOption from "../../Custom/CustomOption";
import { createComplaintApi, updateComplaintApi } from "../../../apis/Settings/complaintsApi";
import toastAlert from "../../../helpers/toastAlert";
import CustomButton from "../../Custom/CustomButton";
import { useHistory } from 'react-router-dom'
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { objectFilter } from "../../../helpers/objectFilter";
import SearchUsers from "../../Common/Search";

const ComplaintForm = ({ type, initialValues }) => {

  const { register, handleSubmit, setValue, reset, control } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { branches } = useBranches();

  const { data } = useComplaintTypes();

  const complaintTypes = data?.data?.data ?? [];

  const history = useHistory();

  const submitForm = async (data) => {
    try {
      setIsLoading(true);
      if (type === 'create') {
        const { user_id, ...rest } = data;
        await createComplaintApi(objectFilter({
          ...rest,
          user_id: user_id?.value
        }));
        history.push(getPathname('settings/complaints'))
      } else if (type === 'update') await updateComplaintApi(initialValues?.id, data)
      toastAlert({ type: 'success' })
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (initialValues) reset(initialValues)
  }, [initialValues, complaintTypes])

  useEffect(() => {
    if (branches?.length && initialValues) setValue('branche_id', initialValues?.branche_id);
  }, [branches])

  return (
    <Card className="p-3">
      <form onSubmit={handleSubmit(submitForm)}>
          <Row className="gy-4 mt-1">

            <Col md={6}>
              <CustomLabel>User</CustomLabel>
              {type === 'create' ? 
                <SearchUsers 
                  control={control}
                />
              : 
                <CustomInput 
                  type="text" 
                  {...register("user_name")} 
                  disabled
                />
              }
            </Col>

            {type === 'update' && 
              <Col md={6}>
                <CustomLabel>Admin</CustomLabel>
                <CustomInput 
                  type="text" 
                  {...register("admin_name")} 
                  disabled
                />
              </Col>
            }

            <Col md={6}>
              <CustomLabel>Type</CustomLabel>
              <select
                className="form-select"
                {...register('type')}
                disabled={type === 'update'}
              >
                {complaintTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </Col>

            <Col md={6}>
              <CustomLabel>Branch</CustomLabel>
              <select
                className="form-select"
                {...register('branche_id')}
                disabled={type === 'update'}
              >
                <CustomOption value="">Select</CustomOption>
                {branches.map(branch => <option key={branch.id} value={branch.id}>{branch.name}</option>)}
              </select>
            </Col>
            
            {type === 'update' && 
              <Col md={6}>
                <CustomLabel>Phone Number</CustomLabel>
                <CustomInput 
                  {...register("user_phone")} 
                  disabled
                />
              </Col>
            }

           <Col md={6}>
              <CustomLabel>Status</CustomLabel>
              <select
                className="form-select text-capitalize"
                {...register('status')}
              >
                {['opened', 'closed'].map(status => (
                  <CustomOption key={status} value={status}>{status}</CustomOption>
                ))}
              </select>
            </Col>

            <Col md={6}>
              <CustomLabel>Problem</CustomLabel>
              <CustomTextArea 
                {...register("problem")} 
              />
            </Col>

            <Col md={6}>
              <CustomLabel>Response</CustomLabel>
              <CustomTextArea 
                {...register("response")} 
              />
            </Col>

            <Col md={6}>
              <CustomLabel>Action</CustomLabel>
              <CustomTextArea 
                {...register("action")} 
              />
            </Col>

          </Row>

          <div className="mt-3">
            <CustomButton
              className="btn btn-success"
              isLoading={isLoading}
            >
              Save
            </CustomButton>
          </div>
      </form>
    </Card>
  );
};

export default ComplaintForm;
