import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import ComplaintForm from "../../../Components/Settings/Complaints/Form";

const CreateComplaint = () => {

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Complaints" title="Details" to="settings/complaints" />
        <ComplaintForm
          type="create"
        />
    </PageContainer>
  );
};

export default CreateComplaint;
