import React from "react";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { useBranches, useComplaintTypes } from "../../../hooks/useQueryHelpers";
import CustomOption from "../../Custom/CustomOption";
import CustomInput from "../../Custom/CustomInput";
import Flatpickr from "react-flatpickr"
import { formatDateTime } from "../../../helpers/timeFormat";
import moment from "moment";

const ComplaintsTable = ({
  data,
  isLoading,
  queryValues,
  setQueryValues,
  debouncedQueryValues,
  setDebouncedQueryValues,
}) => {

  const { data: complaints } = useComplaintTypes();

  const { branches } = useBranches();

  const complaintTypes = complaints?.data?.data ?? [];
  
  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id || "-",
    },
    {
      name: (
        <CustomInput 
          value={debouncedQueryValues.user_name}
          onChange={e => setDebouncedQueryValues(prev => ({...prev, user_name: e.target.value}))}
          placeholder="User"
        />
      ),
      selector: (row) => row.user_name || "-",
    },
    {
      name: <CustomTableHeading>Admin</CustomTableHeading>,
      selector: (row) => row.admin_name || "-",
    },
    {
      name: (
        <select
          className="form-select"
          value={queryValues.type}
          onChange={e => setQueryValues(prev => ({...prev, type: e.target.value}))}
        >
          <CustomOption value=''>Type</CustomOption>
          {complaintTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
      ),
      selector: (row) => row.type || "-",
    },
    {
      name: (
        <select
          className="form-select text-capitalize"
          value={queryValues.branche_id}
          onChange={e => setQueryValues(prev => ({...prev, branche_id: e.target.value}))}
        >
          <CustomOption value=''>Branch</CustomOption>
          {branches.map(branch => (
            <option key={branch.id} value={branch.id}>{branch.name}</option>
          ))}
        </select>
      ),
      selector: (row) => <span className="text-capitalize">{row.branche_name || '-'}</span>,
    },
    {
      name: (
        <select
          className="form-select text-capitalize"
          value={queryValues.status}
          onChange={e => setQueryValues(prev => ({...prev, status: e.target.value}))}
        >
          <CustomOption value=''>Status</CustomOption>
          {['opened', 'closed'].map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      ),
      selector: (row) => <span className="text-capitalize">{row.status || '-'}</span>,
    },
    {
      name: (
        <CustomInput 
          type="number"
          value={debouncedQueryValues.user_phone}
          onChange={e => setDebouncedQueryValues(prev => ({...prev, user_phone: e.target.value}))}
          placeholder="Phone"
        />
      ),
      selector: (row) => row.user_phone || "-",
    },
    {
      name: (
        <Flatpickr
          className="form-control"
          value={queryValues.created_at}
          onChange={date => setQueryValues(prev => ({...prev, created_at: moment(date[0]).format("YYYY-MM-DD")}))}
          placeholder="Date"
        />
      ),
      selector: (row) => <span className="text-capitalize">{formatDateTime(row.created_at) || '-'}</span>,
    },
  ];

  return (
    <DataTableContainer
      data={data}
      columns={columns}
      path={getPathname("settings/complaints")}
      loading={isLoading}
      noPagination
    />
  );
};

export default ComplaintsTable;
