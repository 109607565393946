import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { foodApi } from '../../../../../../apis/Food/api';
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import PizzaSelection from './Selection';
import MaxPrice from '../../MaxPrice';
import { objectFilter } from '../../../../../../helpers/objectFilter';
import LoadingComponent from '../../../../../Common/Loading';

const PizzaList = ({ foodType, initialValues, setValue, register, watch }) => {

  const [list, setList] = useState(null);
  const [data, setData] = useState(null);

  const [default_bread_id, default_size_id] = watch(['default_bread_id', 'default_size_id']);

  const { refetch, isLoading, isRefetching } = useQuery(['all-food-list'], () => foodApi.menu.getAll(objectFilter({ 
    type_id: 1, 
    bread_id: default_bread_id, 
    size_id: default_size_id, 
    page_size: 'all' 
  })), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: {data}}) {
      setList(data.filter(it => it.id !== initialValues?.id));
    }
  })

  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'pizzaList')) {
      refetch()
    } else {
      setValue('halves', null);
      setValue('half_max_price', null);
    }
  }, [foodType, default_size_id, default_bread_id])


  useEffect(() => {
    if (list) {
      setData(list.map(item => {
        const initialVal = initialValues?.halves?.find(half => half.half_id === item.id);
        return {
          id: item.id,
          name: item.name,
          price: initialVal?.extra ?? null,
          included: !!initialVal || false,
          default: initialVal?.default ?? false,
        }
    }))
    }
  }, [list, initialValues])

  useEffect(() => {
    if (data) {
      setValue('halves', data.filter(item => item.included).map(item => ({
        half_id: item.id,
        extra: item.price,
        default: item.default,
      })));
    }
  }, [data])
    
  return (
    <LoadingComponent loading={isLoading || isRefetching}>
      <div className='mt-1 mb-3'>
        <MaxPrice 
          register={register}
          setValue={setValue}
        />
      </div>
      {data ? 
        <PizzaSelection 
          data={data}
          setData={setData}
        />
      : <></>}
    </LoadingComponent>
  )
}

export default PizzaList